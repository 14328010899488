<template>
  <div class="p-b20">
    <div class="p-l-t-r20">
      <!-- 确认信息 -->
      <span class="rem36 c-303133 f500">请确认您的信息</span>
      <div class="mt24 confirm-info">
        <p>姓名</p>
        <van-field label-width="0" v-model="companyInfo.username" readonly />
        <p class="mt20">身份证号</p>
        <van-field label-width="0" v-model="companyInfo.maskIdNo" readonly />
        <p class="mt20">企业名称</p>
        <van-field label-width="0" v-model="companyInfo.enterpriseName" readonly />
        <p class="mt20">社会统一信用代码</p>
        <van-field label-width="0" v-model="companyInfo.enterpriseCertNo" readonly />
        <!-- <p class="mt20">手机号码</p>
        <van-field label-width="0" v-model="companyInfo.phone" />
        <p class="mt20">短信验证码</p>
        <van-field label-width="0" v-model="companyInfo.code">
          <template #extra>
            <span class="rem28 c-4D7BFE" @click="getCode" v-if="countDown == 0">{{
              codetext
            }}</span>
            <span class="rem28 c-4D7BFE" v-else>{{ countDown }}s</span>
          </template>
        </van-field> -->
      </div>
    </div>
    <div class="flex align-center mt14 p-l20">
      <img :src="notChoosePng" alt="" @click="agreeFn(true)" v-if="!agreement" class="mr4 img12" />
      <img :src="choosedPng" alt="" @click="agreeFn(false)" v-else class="mr4 img12" />
      <span class="readed rem26"
        >我已阅读并同意签署<span class="c-4D7BFE" @click="seeContract">列表</span>中的合同文件</span
      >
    </div>
    <div class="mt40 p-l-r20">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :disabled="!agreement"
        @click="submit"
        >提交申请</van-button
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button } from 'vant'
import {
  getfaceResultApi,
  getFaceTokenApi,
  getScanFaceUrlApi,
  getTempByApplyInfoApi,
  getApplyConfirmInfoApi,
} from '@/apis/credit/index'
import { mapState } from 'vuex'
import loadingIndex from '@/utils/overlay'
import { Encrypt, Decrypt } from '@/utils/crypto'

Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)

export default {
  data() {
    return {
      codetext: '获取验证码',
      countDown: 0,
      timer: '',
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
      agreement: false,
      faceBackUrl: window.location.origin, // 刷脸成功返回地址
      companyInfo: {},
    }
  },
  created() {
    if (this.creditApplySeqNo) this.getApplyConfirmInfo()
    // this.companyInfo.idCard = Decrypt(this.companyInfo.idNo)
  },
  computed: {
    ...mapState({
      // companyInfo: state => state.borrow.companyInfo,
      creditApplySeqNo: (state) => state.borrow.creditApplySeqNo,
      creditFaceToken: (state) => state.borrow.creditFaceToken,
    }),
  },
  methods: {
    // 查询信息
    getApplyConfirmInfo() {
      getApplyConfirmInfoApi({
        creditApplySeq: this.creditApplySeqNo,
      }).then((res) => {
        this.companyInfo = res.data
      })
    },
    submit() {
      this.showloading()
      // 获取刷脸token
      getFaceTokenApi({
        applySeq: this.creditApplySeqNo, // 授信申请编号
        callbackUrl: this.faceBackUrl + '/face-result?type=credit', // 刷脸完成回调地址
      })
        .then((result) => {
          if (result.code === 200) {
            this.$store.commit('SET_CREDIT_FACE_TOKEN', result.data.faceToken)
            // if (result.data?.originalUrl) {
            //   this.$store.commit('SET_CREDIT_FACE_URL', result.data.originalUrl)
            //   window.location.href = result.data.originalUrl
            // }
            // this.hideloading()
            getScanFaceUrlApi(result.data.faceToken)
              .then((r) => {
                if (r.code === 200) {
                  window.location.href = r.data.originalUrl
                }
                this.hideloading()
              })
              .catch(() => {
                this.hideloading()
              })
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    // 查询合同列表
    seeContract() {
      getTempByApplyInfoApi({
        creditApplySeq: this.creditApplySeqNo,
      }).then((res) => {
        if (res.code === 200) {
          let contractList = res.data || []
          this.$router.push({
            path: '/view-contract',
            query: {
              file: JSON.stringify(contractList),
              type: 'credit',
              pagePath: '/confirm-info',
            },
          })
        }
      })
    },
    getfaceResult() {
      getfaceResultApi({
        applySeq: this.creditApplySeqNo,
        faceToken: this.creditFaceToken,
      }).then((res) => {
        this.res = res.data
      })
    },
    agreeFn(flag) {
      if (!this.$route.params.isReadFinish) {
        this.$common.toast('请阅读并同意本协议！')
        return
      }
      this.agreement = flag
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding: 0.16rem 0 0.12rem;
  border-bottom: 1px solid #e7e7e7;
}
.confirm-info {
  p {
    color: #585c68;
  }
}
.readed {
  color: #b0b0b0;
}
</style>
